<template>
  <div id="wrapper">
    <div id="sidebar-wrapper">
      <ul class="sidebar-nav">
        <li class="sidebar-brand">
          <a href="#"> Admin </a>
        </li>
        <li>
          <router-link to="/dashboard">All Mitra</router-link>
        </li>
        <!-- <li>
          <a href="#">Mitra MounTPS</a>
        </li>
        <li>
          <a href="#">Mitra MP</a>
        </li>
        <li>
          <a href="#">Mitra MCP</a>
        </li>
        <li>
          <a href="#">Approve Mitra</a>
        </li> -->
        <li>
          <router-link to="/dashboard/usermtps">All Pelanggan MTPS</router-link>
        </li>
        <li>
          <router-link to="/dashboard/kemenkumham">Kemenkumham</router-link>
        </li>
        <!-- <li>
          <a href="#">Atur PPOB</a>
        </li> -->
      </ul> 
    </div>

    <div id="page-content-wrapper">
      <nav class="navbar navbar-light bg-faded">
        <a class="navbar-brand" href="#">Mountrash</a>
        <button @click="logout()" class="btn btn-danger">Logout</button>
      </nav>

      <br /><br />
      <div class="container">
        <div class="row">
          <div class="col-xl-offset-2 col-xl-8">
            <router-view />
            <!-- <partner-data-table :users="$store.state.partners" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PartnerDataTable from '../components/PartnerDataTable.vue';
export default {
  components: {
    // PartnerDataTable
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$store.commit("LOGOUT");
      this.$router.push("/login");
    },
  },
  mounted() {
    this.$store.dispatch("fetchPartners");
  },
};
</script>

<style scoped>
/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Toggle Styles */

.container {
  padding-top: 15px;
  margin-left: 0;
}

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #34495e;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #ecf0f1;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: #2c3e50;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 50px) {
  #wrapper {
    padding-left: 180px;
  }
  #wrapper.toggled {
    padding-left: 0;
  }
  #sidebar-wrapper {
    width: 180px;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }
  #page-content-wrapper {
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

.navbar-nav {
  flex-direction: row;
}

.img-fuid {
  background-repeat: no-repeat;
  background-position: center center;
  height: 500px;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
</style>>